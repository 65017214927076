<div id="roleNew" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a routerLink="/home">{{'views.role.Home' | translate }}</a></li>
        <li><a routerLink="/role/list">{{'views.role.Role list' | translate }}</a></li>
        <li class="active">{{'views.role.New role' | translate }}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.role.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/roles.png" alt="role management" /></div>
    <h1>{{'views.role.New role' | translate }}</h1>

    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <div *ngFor = "let alert of alerts">
                <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                    {{alert.msg}}
                    <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
                     float:right;
            font-size: 22px; "></i>
                </p>
            </div>
            <!-- <form [formGroup]="roleForm">

            </form> -->
            <form name="roleForm" class="form-horizontal" role="form" [formGroup]="roleForm">
                <div class="form-group" show-errors>
                    <label for="role.name" class="col-sm-2 control-label ">{{'views.role.Name' | translate }}</label>
                    <!-- <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div> -->
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleForm.controls['name'].hasError('required') && roleForm.controls['name'].touched">
                        <span class="hint-text call-send-text">{{'views.role.Required' | translate }}</span>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.technicalId" class="col-sm-2  control-label ">{{'views.role.Technical ID' |
                        translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="technicalId"
                            (keyup)="validateTechnicalId($event)">
                    </div>
                    <!-- <div class="col-sm-7">
                        <input type="text" 
                               name="technicalId" 
                               class="form-control" 
                               id="role.technicalId" 
                               ng-model="role.technicalId"        
                               ng-pattern="/^[a-zA-Z0-9]+$/" required
                               unique-value dir-url="/role/checkTechnicalId"/>
                    </div> -->
                    <!-- <div class="col-sm-3">
                        <p class="help-block" ng-if="roleForm.technicalId.$error.required">{{'views.role.Required' | translate }}</p>
                        <p class="help-block" ng-if="roleForm.technicalId.$error.pattern">{{'views.role.The technical ID can only contain alphanumeric values.' | translate }}</p>
                        <p class="help-block" ng-if="roleForm.technicalId.$error.uniqueValue">{{'views.role.The technical ID already exists.' | translate }}</p>
                    </div> -->
                    <div class="col-sm-3">
                        <p class="help-block"
                            *ngIf="roleForm.controls['technicalId'].hasError('required') && roleForm.controls['technicalId'].touched">
                            {{'views.role.Required' |
                            translate }}</p>
                        <p class="help-block" *ngIf="roleForm.controls['technicalId'].hasError('pattern')">
                            {{'views.role.The technical ID can only contain alphanumeric values.' | translate }}</p>
                            <p class="help-block" *ngIf="technicalInvalidFlag=='true'">{{'views.role.The technical ID already exists.' | translate }}</p>
                        
                        </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.description" class="col-sm-2 control-label ">{{'views.role.Description' | translate
                        }}</label>
                    <div class="col-sm-7">
                        <!-- <input type="text" class="form-control" id="role.description" name="description" ng-model="role.description"/> -->
                        <input type="text" class="form-control" formControlName="description">
                    </div>

                </div>

                <div class="form-group" show-errors>
                    <label for="role.serviceId" class="col-sm-2 control-label ">{{'views.role.Service' | translate
                        }}</label>

                    <div class="col-sm-7">
                        <input  placeholder="Search Service" formControlName="serviceId"
                        (keyup)="filterService($event.target.value)" 
                        [matAutocomplete]="auto"
                        class="example-input form-control">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updatePortCode($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{option.name}}
                            </mat-option>
                          </mat-autocomplete>
                        
                    </div>
                    <!-- <div class="col-sm-3">
                        <p class="help-block" ng-if="roleForm.serviceId.$error.required">{{'views.role.Required' |
                            translate }}</p>
                    </div> -->
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button" class="btn btn-default" (click)="saveRole()" [disabled]="(!roleForm.valid)&&(invalidFlag)">
                            <span class="glyphicon glyphicon-floppy-disk"
                                style="margin-right: 10px"></span>{{'views.role.Save' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>
</div>