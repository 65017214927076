import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../common.service';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '../config';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordService } from '../password.service';
import { User } from '../User_Management/Users';
import { data } from 'jquery';
import { Router } from '@angular/router';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.css']
})
export class SelfServiceComponent implements OnInit {
  userForm: FormGroup = new FormGroup({ 'firstname': new FormControl(null, Validators.required),
  'lastname': new FormControl(null, Validators.required),
  'email': new FormControl(null, Validators.required),
  'currentLocaleDisplayName': new FormControl(null, Validators.required),});
  tenantValue: string;
  constructor(private commonService: CommonService, private translateService: TranslateService, private passwordService: PasswordService, private router: Router) {}
  phoneForm: FormGroup = new FormGroup({
    'mobile': new FormControl('')
  });
  telephoneOtpForm: FormGroup = new FormGroup({
    'otp': new FormControl(null)
  });
  preferredMFAForm: FormGroup = new FormGroup({
    'radio': new FormControl(null)
  });
  userId: String;
  orgId:String;
  userdetails: any;
  validateMobilOTP: boolean;
  OTPResponse: boolean;
  selectedOrgName = '';
  organizations: any=[];
  mobilephonecallchecked: boolean;
  infomsg: string;
  headerName: string;
  dangermsg: string;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  mobileNumber: any;
  registerOtpButtonClicked: boolean;
  messageMedium: string;
  alerts = [];
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  localesDisplayNames = CONFIG.LOCALES.locales;
  showOTPSection: boolean;
  otpapplastchangeddate: any;
  user: User = {
    azureServiceId: undefined,
    email: '',
    firstname: '',
    groupId: null,
    lastname: '',
    mainLanguage: '',
    mobile: '',
    userType: ''
  };
  currentUser:any;
  preferredMFA: string;
  CallbackResponse:any;
  ConfirmCallBack:any;
  AllCallBacks:any;
  ResetCallBack:any;
  FirstPhase:boolean;
  ValidatePhoneNumber:boolean;
  PreferredMFASet:string;
  ngOnInit(): void {
    let Intmobile="";
    this.currentUser = JSON.parse(localStorage.getItem("currentUserDetails"));
    if(this.currentUser==null && this.currentUser==undefined){
      this.router.navigate(['/dis']);
    }
    this.PreferredMFASet=this.currentUser?.preferences?.preferredMFA;
    const sendData='';
      this.commonService.getSelfService(sendData,"service","UserSelfService").subscribe(data =>{
        this.AllCallBacks=data;
        if(this.AllCallBacks.callbacks.length == 1){
          if(this.AllCallBacks.callbacks[0].output[0].value=="Invalid Session"){
            sessionStorage.clear();
            localStorage.removeItem('currentUser');
            this.router.navigate(['/dis']);
          }
        }
        this.commonService.getCurrentOrg().subscribe(data => {
            if(data.body !=null){
              this.selectedOrgName = data.body[`name`];
              this.commonService.selectedOrg = data.body;
            }
            else
            {
              if(this.organizations.length >0)
              this.selectedOrgName = this.organizations[0].name;
              this.commonService.selectedOrg = this.organizations[0];
            }
          }, error => {}
        );
        this.userId=this.currentUser['_id']
        this.orgId=(this.commonService.selectedOrg!=undefined && this.commonService.selectedOrg!==null) ? this.commonService.selectedOrg['id'] : '';
        this.user.firstname=this.AllCallBacks.callbacks[0].input[0].value
        this.user.lastname=this.AllCallBacks.callbacks[1].input[0].value
        this.user.email=this.currentUser['mail']
        this.user.userType=this.currentUser['userType']
        this.user.mobile=this.AllCallBacks?.callbacks[3]?.output[6]?.value
        this.user.mainLanguage=(this.AllCallBacks.callbacks[2].input[0].value == '' || this.AllCallBacks.callbacks[2].input[0].value === null ) ? 'EN' : (this.AllCallBacks.callbacks[2].input[0].value).toUpperCase()
        
        this.userForm.get('currentLocaleDisplayName').setValue(this.user.mainLanguage);
        
        this.userForm.patchValue({
          'firstname':this.AllCallBacks.callbacks[0].input[0].value,
          'lastname': this.AllCallBacks.callbacks[1].input[0].value,
          'email':this.currentUser['mail'],
          'currentLocaleDisplayName': this.user.mainLanguage
        });
        this.headerName = this.user.firstname;
        
        if (this.user.userType === 'internal') {
          this.userForm.get('firstname').disable();
          this.userForm.get('lastname').disable();
        }
        this.userForm.get('email').disable();
        this.commonService.userDetails(this.userId).subscribe(data =>
        {
          this.userdetails=data;
          //if(this.userdetails.telephoneNumber!=undefined){
            Intmobile=this.AllCallBacks?.callbacks[3]?.output[6]?.value;
            this.phoneForm.get('mobile').setValue(this.AllCallBacks?.callbacks[3]?.output[6]?.value);
         //}

          if(this.userdetails.otpappLastchanged !== undefined && this.userdetails.otpappLastchanged !== null) {
            const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const d = new Date(this.userdetails.otpappLastchanged);
            this.otpapplastchangeddate = d.toLocaleDateString('en-GB', options);
          }
          this.PreferredMFASet=(this.userdetails?.preferences.preferredMFA)?.toLowerCase();
        });
        this.tenantValue = this.currentUser['azureTenant'];
     
    this.commonService.getCompLanguage().subscribe(language => {
      if (language) {
        this.userForm.get('currentLocaleDisplayName').setValue(language);
        document.getElementById('language')[`value`] = language;
      }
    });
  
    this.phoneForm.get('mobile').valueChanges.subscribe(mobiledata => {
      if(mobiledata !=null)
        {
        const formphoneNumber = mobiledata?.internationalNumber;
        const phoneNumber = parsePhoneNumber(formphoneNumber);
        if (phoneNumber) {
          if(phoneNumber.isValid())
          {
            this.ValidatePhoneNumber=false
            if(this.userForm.valid){
                this.ValidatePhoneNumber=false;
            }else{
              this.ValidatePhoneNumber=true;
            }
            if (this.preferredMFA){
              this.PreferredMFASet = this.preferredMFA;
            }
          }
          else
          {
            this.ValidatePhoneNumber=true;
            if (this.PreferredMFASet === 'sms' || this.PreferredMFASet === 'voice') {
              this.PreferredMFASet = this.PreferredMFASet = '';
            }
          }
        }
      }
      else
      {
        this.ValidatePhoneNumber=true;
      }
    });
  });
  }
  resetPassword(email) {
    // this.resetPasswordButtonClicked = true;
    const sendDate='';
    this.commonService.getSelfService(sendDate,"service","ResetPassword").subscribe(data =>{
      this.ResetCallBack=data;
      if(this.ResetCallBack.callbacks[0].output[1].value=="0"){
        this.translateService.get('views.js.alerts.user.The reset password email has been sent.').subscribe(res => {
          this.infomsg = res;
          this.alerts.push({ type: 'info', msg: res });
          // setTimeout(() => {
          this.deleteAlert(res);
          // }, 5000)
          window.scroll(0, 0);
        });
      }else{
        this.translateService.get('views.js.alerts.user.ResetTooEarlyMsg').subscribe(res => {
          this.dangermsg = res;
          this.alerts.push({ type: 'danger', msg: res });
          this.deleteAlert(res);
          window.scroll(0, 0);
        });
      }
    });
  }
  deleteAlert(msg) {
    setTimeout(() => {
      this.alerts.splice(this.alerts.findIndex(alert => alert.msg === msg), 1);
    }, 5000);
  }
  changeLanguage() {
    
    this.translateService.use(this.userForm.get('currentLocaleDisplayName').value);
    this.commonService.setComponentLanguage(this.userForm.get('currentLocaleDisplayName').value);
    document.getElementById('language')[`value`] = this.userForm.get('currentLocaleDisplayName').value;
  }
  sendOTP(messageMedium, mobileNumber) {
    this.messageMedium = messageMedium;
   
    const sendData='';
    this.commonService.getSelfService(sendData,"service","UserSelfService").subscribe(data =>{
        this.AllCallBacks=data;
        this.AllCallBacks.callbacks[0].input[0].value=this.userForm.get('firstname').value;
        this.AllCallBacks.callbacks[1].input[0].value=this.userForm.get('lastname').value;
        this.AllCallBacks.callbacks[2].input[0].value=(this.userForm.get('currentLocaleDisplayName').value).toLowerCase();
        if(this.user.userType =="external" && this.tenantValue =="global"){
          if(this.AllCallBacks.callbacks[3].output[0].value=="telephoneNumber"){
            if(this.AllCallBacks.callbacks[3].input[0].value !== this.phoneForm.get('mobile').value.internationalNumber){
              this.AllCallBacks.callbacks[3].input[0].value=mobileNumber.internationalNumber;
            }
          }
        }
        
        this.commonService.getSelfService(this.AllCallBacks,"service","UserSelfService").subscribe(data1 => {
          
          if(data1.callbacks[0].type=="ChoiceCallback"){
            if(this.messageMedium =="sms"){
              data1.callbacks[0].input[0].value=0;
            }else if(this.messageMedium =="call"){
              data1.callbacks[0].input[0].value=1;
            }
           
            this.commonService.getSelfService(data1,"service","UserSelfService").subscribe(data2 => {
             
              this.CallbackResponse = data2;
              this.showOTPSection = true;
            });
          }
        });
    });
    
  }
  checkMobile(phoneForm, mobile) {
    
    if (phoneForm.controls.mobile.value === undefined) {
      const mob = phoneForm.controls.mobile.value.internationalNumber === null ? this.mobileNumber : phoneForm.controls.mobile.value.internationalNumber;
      const regex = new RegExp('^([0|\+[0-9]{1,5})?([0-9]{10})$');
      
      const valid = regex.test(mob.split(' ').join(''));
      
      if (valid) {
        return false;
      }
      else {
        return true;
      }
     
    }
    else {
      return false;
    }

  }
  registerOtp() {
    
    const email=this.user.email;
    const orgId=this.orgId;
    this.registerOtpButtonClicked = true;
    
    const emptydata='';
      this.commonService.getSelfService(emptydata,"service","AuthAppRegistration").subscribe(Authresponsedata => {
      
      if(Authresponsedata.callbacks[0]!=null && Authresponsedata.callbacks[0]!=undefined){
        if(Authresponsedata.callbacks[0].output[1].value==="0"){
          this.translateService.get('views.js.alerts.user.An email is sent to the user with an authenticator app registration link.').subscribe(res => {
            this.infomsg = res;
            this.alerts.push({ type: 'info', msg: res });
            this.deleteAlert(res);
            window.scroll(0, 0);
          });
        }else{
          this.translateService.get('Mail Not Send').subscribe(res => {
            this.infomsg = res;
            this.alerts.push({ type: 'danger', msg: res });
            this.deleteAlert(res);
            window.scroll(0, 0);
          });
        }
      }
    });
  }
  revokeOtp() {
   
    // const email=this.user.email;
    // this.otpapplastchangeddate = undefined;
    // this.commonService.revokeUserOtp(email).subscribe(data => {
      
    //   this.translateService.get('views.js.alerts.user.OTPRevokedMsg').subscribe(res => {
    //     this.infomsg = res;
    //     this.alerts.push({ type: 'info', msg: res });
    //     this.deleteAlert(res);
    //     window.scroll(0, 0);
    //   });
    // });
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  verifyOTP(mobileNumber, otpCode) {
    const codes = '{"mobileNumber": "' + mobileNumber.internationalNumber.split(' ').join('') + '", "otpCode": "' + otpCode + '"}';
    if(this.showOTPSection && this.ConfirmCallBack!=undefined){
      this.CallbackResponse=this.ConfirmCallBack
    }
   
    if(this.CallbackResponse.callbacks[1].type=="NameCallback"){
      this.CallbackResponse.callbacks[1].input[0].value=otpCode;
    }
    
    this.commonService.getSelfService(this.CallbackResponse,"service","UserSelfService").subscribe(data => {
      this.ConfirmCallBack=data;
      
      if(data.callbacks[2] == undefined){
        (this.ConfirmCallBack.callbacks[0].input[0].value === 'sms') ? this.validateMobilOTP = true : this.mobilephonecallchecked = true;
        this.showOTPSection = false;
        this.user.mobile = mobileNumber.internationalNumber.split(' ').join('');
        this.translateService.get('views.js.alerts.emailprocessing.otpsuccess').subscribe(msgSec => {
          this.alerts.push({ type: 'info', msg: msgSec });
          this.deleteAlert(msgSec);
          window.scroll(0, 0);
        });
      }else{
        this.OTPResponse=false;
        this.validateMobilOTP = false;
        this.mobilephonecallchecked = false;
        this.translateService.get('views.js.alerts.emailprocessing.otpfail').subscribe(msgSec => {
          
          this.alerts.push({ type: 'danger', msg: msgSec });
          this.deleteAlert(msgSec);
          window.scroll(0, 0);
        });
      }
    });
    this.FirstPhase=true;
  }
  

  submit() {
 
    if(this.preferredMFA == undefined){
      this.preferredMFA=this.PreferredMFASet
    }
    if(!this.FirstPhase){
      this.AllCallBacks.callbacks[0].input[0].value=this.userForm.get('firstname').value;
      this.AllCallBacks.callbacks[1].input[0].value=this.userForm.get('lastname').value;
      this.AllCallBacks.callbacks[2].input[0].value=(this.userForm.get('currentLocaleDisplayName').value).toLowerCase();
      if(this.tenantValue == "global" && this.user.userType ==="external"){
        if(this.AllCallBacks.callbacks[3].input[0].value === this.phoneForm.get('mobile').value?.internationalNumber){
          this.AllCallBacks.callbacks[3].input[0].value=this.phoneForm.get('mobile').value?.internationalNumber
        }
      }
     // if(this.preferredMFA!==undefined && this.preferredMFA!=null){
        this.commonService.getSelfService(this.AllCallBacks,"service","UserSelfService").subscribe(Savedata => {
          if(Savedata.callbacks[0]?.output[0]?.value!=undefined && Savedata.callbacks[0].output[0].value=="preferences/preferredMFA"){
            if(this.preferredMFA!==undefined && this.preferredMFA!=null){
              Savedata.callbacks[0].input[0].value=this.preferredMFA;
            }
            this.commonService.getSelfService(Savedata,"service","UserSelfService").subscribe(PFresponse => {
              if(PFresponse.callbacks[0]?.output[1]?.value=="0"){
                this.translateService.get('views.selfservice.Changes have been saved.').subscribe(alertmsg => {
                  // this.alerts.push(alertmsg);
                  this.alerts.push({ type: 'info', msg: alertmsg });
                  this.deleteAlert(alertmsg);
                  window.scroll(0, 0);
                  window.location.reload();
                }, (error) => {
                  this.alerts.push({ type: 'danger', msg: 'HTTP ' + error.status + ' - ' + error.statusText });
                  this.deleteAlert('HTTP ' + error.status + ' - ' + error.statusText);
                  window.scroll(0, 0);
                });
              }else{
                this.alerts.push({ type: 'danger', msg: 'User Details not updated' });
                this.deleteAlert('User Details not updated');
                window.scroll(0, 0);
              }
            });
          }else{
            this.translateService.get('views.selfservice.Changes have been saved.').subscribe(alertmsg => {
              // this.alerts.push(alertmsg);
              this.alerts.push({ type: 'info', msg: alertmsg });
              this.deleteAlert(alertmsg);
              window.scroll(0, 0);
              window.location.reload();
            }, (error) => {
              this.alerts.push({ type: 'danger', msg: 'HTTP ' + error.status + ' - ' + error.statusText });
              this.deleteAlert('HTTP ' + error.status + ' - ' + error.statusText);
              window.scroll(0, 0);
            });
          }
        });
     // }
    }else{
      if(this.ConfirmCallBack.callbacks[0].output[0].value!=undefined && this.ConfirmCallBack.callbacks[0].output[0].value=="preferences/preferredMFA"){
        this.ConfirmCallBack.callbacks[0].input[0].value=this.preferredMFA;
      }
      this.commonService.getSelfService(this.ConfirmCallBack,"service","UserSelfService").subscribe(Savedata => {
        
        this.translateService.get('views.selfservice.Changes have been saved.').subscribe(alertmsg => {
          // this.alerts.push(alertmsg);
          this.alerts.push({ type: 'info', msg: alertmsg });
          this.deleteAlert(alertmsg);
          window.scroll(0, 0);
        }, (error) => {
          this.alerts.push({ type: 'danger', msg: 'HTTP ' + error.status + ' - ' + error.statusText });
          this.deleteAlert('HTTP ' + error.status + ' - ' + error.statusText);
          window.scroll(0, 0);
        });
    });
  }
    
  }
  checkDisabledSave() {
    // ng-disabled="preferredMFAForm.$invalid || userForm.$invalid || ((phoneForm.mobile.$invalid || verificationRequired) && user.userType !== 'internal')"
    if (!this.userForm.valid || ((!this.phoneForm.valid || this.user.mainLanguage === '') && this.user.userType !== 'internal')&&(this.tenantValue =='swiss')) {
      return true;
    }
    return false;
  }

}
