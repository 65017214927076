import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../config';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('currentUser')) {
      const ssoConfig = CONFIG.getSSOConfig();
      // logged in so end FR session first
      const user = JSON.parse(localStorage.getItem('currentUser'));
      const endSessionUrl = `${ssoConfig.end_session_endpoint}?id_token_hint=${user.idToken}&post_logout_redirect_uri=${ssoConfig.post_logout_redirect_uri}`;
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = endSessionUrl;
    }
  }

}
