import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PermissionService } from 'src/app/permission.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-userrequest-detail',
  templateUrl: './userrequest-detail.component.html',
  styleUrls: ['./userrequest-detail.component.css']
})
export class UserrequestDetailComponent implements OnInit , AfterViewInit {
  userrequestForm: FormGroup = new FormGroup({
    givenName: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    sn: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    mainLanguage: new FormControl('', Validators.required),
    mail: new FormControl(''),
    telephoneNumber: new FormControl({ value: '', disabled: false }),

  });
  dataSource = new MatTableDataSource();
  alerts =[];
  roledataSource: MatTableDataSource<any>;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  id: number;
  user;
  photourl: any;
  uniqueValue;
  roleRequestData: any[];
  @ViewChild('myTable', { static: false }) table: MatTable<any>;
  roleRequestcount: any;
  roledisplayedColumns: string[] = [ 'organization'];
  newLinkButtonClicked = false;
  mobileFlag: boolean;
  OrgData
  organizationcount: any;
  initialFormValues: any;
  updatedFormFields ={};
  suspendjson;
  constructor(private cdr: ChangeDetectorRef,private translate:TranslateService,private permission: PermissionService , private commonService: CommonService , private router: Router , private route: ActivatedRoute) { }
  ngAfterViewInit() {
    setTimeout(() => {
      this.cdr.detectChanges();
        }, 0);
    // this.commonService.userRequestDetails(this.id).subscribe(data =>
    //   {
        
    //   });
        }
  authorized() {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  authorizedRoleAssignments()
  {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  applyFilter(event: Event) {

  }
  newLink(row)
  {

     const request = {
    "mail":this.user.mail,
    "orgId":this.OrgData[0]._id,
    "sendEmail":true
};

  this.commonService.activateUser(request).subscribe(data =>
    {
      this.translate.get('views.js.alerts.userrequest.The new activation mail has been sent.').subscribe(res => {
        //our alert message 
        this.alerts.push({type: 'info', msg: res});
      });
    },
    err =>
    {
    }
    );
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.commonService.userRequestDetails(this.id).subscribe(data =>
      {
        this.user = data;
        this.userrequestForm.get('givenName').setValue(this.user.givenName);
        this.userrequestForm.get('sn').setValue(this.user.sn);
        this.userrequestForm.get('mail').setValue(this.user.mail);
        if(this.user['preferences']==null||this.user['preferences'].preferredLanguage =='' ||this.user['preferences'].preferredLanguage === null )
          this.userrequestForm.get('mainLanguage').setValue("EN");

        else
        this.userrequestForm.get('mainLanguage').setValue((this.user['preferences'].preferredLanguage).toUpperCase());
      
        this.userrequestForm.get('telephoneNumber').setValue(this.user.telephoneNumber);
        this.initialFormValues = this.userrequestForm.value;

        this.OrgData = data.memberOfOrg;
        if(this.user.suspendedJson!=null)
        {
          this.suspendjson=JSON.parse(this.user.suspendedJson);
          // this.suspendjson['validity']=new Date(this.suspendjson['validity']);
          // this.suspendjson['linkStartDate']=new Date(this.suspendjson['linkStartDate']);
        }
       this.organizationcount = data.memberOfOrg.length;
        this.dataSource = this.OrgData;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.onChanges();
        //this.table.renderRows(); // Ensure this is called after data is assigned

      });

  }
  onChanges(): void {
    this.userrequestForm.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values
      for (const field in values) {
        if (values[field]!=null &&values[field] !== this.initialFormValues[field]) {
          this.updatedFormFields[field] = values[field]; // Update stored initial value on change
        }
      }
});  }
onLangChange(event: any) {
  this.userrequestForm.get('mainLanguage').setValue(event.target.value);
  this.userrequestForm.get('mainLanguage').updateValueAndValidity();
  this.onChanges();
}
  checkUniqueValue(email) {
    const e = this.userrequestForm.value.mail;
    this.commonService.uniqueValue(e, '').subscribe(data => {
       ;
      this.uniqueValue = data;

    },
      err => {
      }
    );
  }
  onSubmit(userrequestForm)
  {
    
    if (this.userrequestForm.get('telephoneNumber').value != null)
    {
      this.user.telephoneNumber = this.userrequestForm.get('telephoneNumber').value.internationalNumber;
    }
    const changes = [];

    //const currentValues = this.organizationDetailRequest.value;
    // Iterate over fields and check if there are changes
    for (const field in this.updatedFormFields) {
      
      if (this.updatedFormFields[field]!=null&&this.updatedFormFields[field] !== this.initialFormValues[field]) {
       if(field==='telephoneNumber')
       {
        if(field==='telephoneNumber' && this.updatedFormFields[field]!=null)
        {
          this.user.telephoneNumber = this.userrequestForm.get('telephoneNumber').value.internationalNumber;
          this.updatedFormFields[field] = this.user.telephoneNumber;
        }
        changes.push({
          operation: "replace",
          field: "telephoneNumber",
          value: this.updatedFormFields[field]
        });
      }
     else  if(field==='mainLanguage')
        {
         if(field==='mainLanguage' && this.updatedFormFields[field]!=null)
         {
          const value ={
            "preferredLanguage":this.updatedFormFields[field]
          }
         
         changes.push({
           operation: "replace",
           field: "preferences",
           value: value
         });
        }
       }
      else
      {
        changes.push({
          operation: "replace",
          field: field,
          value: this.updatedFormFields[field]
        });
      }
      }
    }

    if (changes.length > 0) {
      this.commonService.updateUser(this.id,changes).subscribe(data => {
        if(data.code==0)
        this.router.navigate(['userrequest/list']);
      else
      this.alerts.push({ type: 'danger', msg:data.message});

      },err =>{
       
          
            this.alerts.push({ type: 'danger', msg:"Unable to update org" });
          
      }
    );
    } else {
    }
   }
   checkNumber()
  {
    if (this.userrequestForm.controls.telephoneNumber.value != undefined || this.userrequestForm.controls.telephoneNumber.value != null)
    {
      const mob = this.userrequestForm.controls.telephoneNumber.value.internationalNumber === null ? this.user.telephoneNumber : this.userrequestForm.controls.telephoneNumber.value.internationalNumber ;
      const regex = new RegExp('^([0|\+[0-9]{1,5})?([0-9]{10})$');
      const valid = regex.test(mob.split(' ').join(''));
      this.user.telephoneNumber = this.userrequestForm.controls.telephoneNumber.value.internationalNumber;
      if (valid)
      {
        this.mobileFlag = false;
      }
      else
      {
        this.mobileFlag = true;
      }
    }
    else
    {
      this.mobileFlag = false;
    }
  }
  gotoUserRequestListPage()
  {
    if (this.commonService.previoususerlistlocation )
    {
      this.router.navigate([ this.commonService.previoususerlistlocation ])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    else
    {
      this.router.navigate(['/user/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });

    }
  }
  navigateorgDetail(row)
  {
    if(this.permission.isAdmin)
      {
    this.router.navigate(['/organiztaion/'+row.id])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
  }

}
