import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RolesService } from '../../roles-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from '../../permission.service';
import { ServiceManagementService } from 'src/app/Service_Management/service-management.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable } from 'rxjs';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.css']
})
export class RoleDetailsComponent implements OnInit , AfterViewInit {

  filter: any ={};

  roleForm: FormGroup;
  isLoadingResults: boolean;
  role: any;
  id: any;
  updatedFormFields={};
  serviceDetails ={};
  initialFormValues: any;
  alerts: any[] = [];
  resultsLength=0;
  isLoading = true;

  constructor(private commonService :CommonService,private roleService: RolesService, private servicemgmntService:ServiceManagementService,private route: ActivatedRoute, private router: Router, private permission: PermissionService) {
    this.roleForm = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'technicalId': new FormControl(null, ),
       'description': new FormControl(null)    });
  }
  authorized()
  {
    return this.permission.isAdmin || this.permission.isRoleAdmin;

  }
  authorizedRoleAssignments()
  {
    return this.permission.isAdmin || this.permission.isRoleAssignmentAdmin;

  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
    this.route.params.subscribe((response: any) => {
      this.id = response.id;
      this.roleService.getRoleDetails(this.id).subscribe((data: any) => {
         ;
        this.role = data.body;
        if(this.role.serviceId!=null)
        { 
          this.isLoading =true;
          this.servicemgmntService.getServiceDetailswithId(this.role.serviceId).subscribe(data =>{
            this.serviceDetails = data.body;
            this.isLoading =false;

          })
          this.roleForm.patchValue({
            'name': this.role.name,
            'technicalId': this.role.technicalId,
            'description': this.role.description
          });
          this.initialFormValues = this.roleForm.value;

        }
        else
        {
          this.isLoading =false;
          this.roleForm.patchValue({
            'name': this.role.name,
            'technicalId': this.role.technicalId,
            'description': this.role.description
          });
          this.initialFormValues = this.roleForm.value;

        }
       this.onChanges();
      });
    });
  }
  onChanges(): void {
    this.roleForm.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values
      for (const field in values) {
        if (values[field] !== this.initialFormValues[field]) {
          this.updatedFormFields[field] = values[field]; // Update stored initial value on change
        }
      }
});  }
  authorizedServices()
  {
    return this.permission.isAdmin;
  }
 
  
  // true if navigation is successful
  
 
  submit() {
    const changes = [];
    //const currentValues = this.organizationDetailRequest.value;
    // Iterate over fields and check if there are changes
    for (const field in this.updatedFormFields) {
      
      if (this.updatedFormFields[field]!=null&&this.updatedFormFields[field] !== this.initialFormValues[field]) {

        changes.push({
          operation: "replace",
          field: field,
          value: this.updatedFormFields[field]
        });
      }
    }

    if (changes.length > 0) {
      this.roleService.updateRoleDetails(this.id,changes).subscribe(data => {
        this.router.navigate(['role/list']);
      },err =>{
       
          
            this.alerts.push({ type: 'danger', msg:"Unable to update org" });
          
      }
    );
    } else {
    }
  }
 

}
