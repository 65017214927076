<div id="roleDetail" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a routerLink="/home">{{'views.role.Home' | translate }}</a></li>
        <li><a routerLink="/role/list">{{'views.role.Role list' | translate }}</a></li>
        <li class="active">{{role.name}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.role.Log out' | translate }}</a></li>
    </ol>

    <h1>{{role.name}}</h1>
    <!-- <alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)" class="">{{alert.msg}}</alert> -->
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="subtitleicon">
                <img src="assets/images/homepage/roles.png" alt="role management" width="30px" />
            </div><span class="">{{'views.role.Role details' | translate }}</span>
            <div class="loader" *ngIf="isLoading">
                <div class="dot" style="--dot-index:0;"></div>
                <div class="dot" style="--dot-index:1;"></div>
                <div class="dot" style="--dot-index:2;"></div>
              </div>
        </div>
        <div class="panel-body">
            <form  [hidden] ="isLoading"name="roleForm" class="form-horizontal" role="form" [formGroup]="roleForm">
                <div class="form-group" show-errors>
                    <label for="role.status" class="col-sm-2 control-label ">{{'views.role.Status' | translate
                        }}</label>
                    <div class="col-sm-7">
                        <img style="width: 25px" *ngIf="role.status == 'suspended'"
                            src="assets/images/icons/bullet_red.png" alt="{{'views.role.suspended' | translate }}"
                            title="suspended" />
                        <img style="width: 25px" *ngIf="role.status == 'active'"
                            src="assets/images/icons/bullet_green.png" alt="{{'views.role.active' | translate }}"
                            title="active" />
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.name" class="col-sm-2 control-label ">{{'views.role.Name' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" [value]="role.name"
                            formControlName="name" />
                    </div>
                    <div class="col-sm-1">
                        <p class="help-block"
                            *ngIf="roleForm.controls['name'].hasError('error') && roleForm.controls['name'].touched">
                            {{'views.role.Required' | translate
                            }}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.technicalId" class="col-sm-2 control-label ">{{'views.role.Technical ID' |
                        translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.technicalId" name="technicalId"
                            formControlName="technicalId" readonly />
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.description" class="col-sm-2 control-label ">{{'views.role.Description' | translate
                        }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.description" name="description"
                            formControlName="description" />
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedServices()&&serviceDetails!=null">
                    <label class="col-sm-2 control-label " style="margin-top: 10px">{{'views.role.Service' | translate
                        }}</label>
                    <div class="col-sm-7">
                        <p><span class='form-group'> <img style="width: 25px"
                                    *ngIf="serviceDetails.status == 'suspended'"
                                    src="assets/images/icons/bullet_red.png"
                                    alt="{{'views.role.suspended' | translate }}" title="suspended" />
                                <img style="width: 25px" *ngIf="serviceDetails.status == 'active'"
                                    src="assets/images/icons/bullet_green.png"
                                    alt="{{'views.role.active' | translate }}" title="active" />
                                <a  [routerLink]="['/service/' + serviceDetails?.id]"><span
                                        class="">{{serviceDetails.name}}</span></a> </span></p>
                    </div>
                </div>
             
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button" class="btn btn-default" (click)="submit()"
                            [disabled]="roleForm.invalid">
                            <span class="glyphicon glyphicon-floppy-disk"
                                style="margin-right: 10px"></span>{{'views.role.Save' | translate }}</button>
                    </div>
                </div>


            </form>
        </div>
    </div>

   

    <app-terms-conditions></app-terms-conditions>

</div>