import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RolesService } from '../../roles-service.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../common.service';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { PermissionService } from '../../permission.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-admin-role-assignment-new',
  templateUrl: './admin-role-assignment-new.component.html',
  styleUrls: ['./admin-role-assignment-new.component.css']
})
export class AdminRoleAssignmentNewComponent implements OnInit {

  roleAssignmentForm: FormGroup;
  currentUsers: any;
  originalUsers: any;
  selectedUser: any;
  currentRoles: any;
  originalRoles: any;
  selectedRole: any;
  availableServices: any;
  selectedService: any;
  availableOrganizations: any =[];
  originalOrganizations: any;
  currentOrganization: any;
  date = new Date();
  showhelp = false;
  selectedfile = {};
  selectedOrgName: string;
  selected = false;
  showprogress = false;
  alerts = [];
  progresswidth;
  progressmax = 0;
  orgAdminFlag = false;
  progressvalue = 0;
  selectedorg: any;
  adminRoles =["ciam-admin","gsd-gas-admin","adminOfOrg"]
  isMenuDisabled: boolean = false;
  constructor(private roleService: RolesService, private router: Router, private translateService: TranslateService, private commonService: CommonService,  private permission: PermissionService ) {
    this.roleAssignmentForm = new FormGroup({
      
      'user': new FormControl(null, [Validators.minLength(3),Validators.required]),
      'role': new FormControl(null,[Validators.required]),
      'organization': new FormControl(null)
    });
  }
  fetchUsers() {
    this.alerts=[];
    if (this.roleAssignmentForm.get('user').status=="VALID") {
      this.roleService.getAllUsers(this.roleAssignmentForm.get('user').value).subscribe(
        data => {
          if(data.body.length>0)
          {
            this.isMenuDisabled = false;
            this.currentUsers = data.body;
          this.originalUsers = data.body;
          
          }
          else
          {
            this.selectedorg ="";
            this.selectedUser="";
            this.isMenuDisabled = true;
            this.translateService.get('views.Admin.appadmin.onlyInternal').subscribe(res => {
              this.alerts.push({ type: 'danger', msg: res });
            });
          }
          
             },
        error => {
        }
      );
    }
  }
  roleChange(event: MatSelectChange) {
    const roleval = event.value;
    // Additional logic herex
    if(roleval==='adminOfOrg')
      {
        this.orgAdminFlag = true;
      
      }
      else
      {
        this.orgAdminFlag=false;
      }
  }
  ngOnInit(): void {
  this.getOrgs();
  }
  getOrgs()
  {
    this.roleService.getAllOrganizations().subscribe(data =>{
      this.availableOrganizations = data.body;
      this.originalOrganizations = data.body;
    });
      this.currentOrganization = this.commonService.selectedOrg;
      this.selectedOrgName = this.commonService.selectedOrg[`name`];
  }
  selectUser(user) {
  
    this.selectedUser = user;
   
    
    this.roleAssignmentForm.get('user').setValue((user.givenName + ' ' + user.sn + ' (' + user.mail + ')'));
  }
  filterUsers(searchString) {
    if(this.originalUsers!=undefined)
      {
    this.currentUsers = this.originalUsers.filter(user => {
      return (user.givenName.toLowerCase().includes(searchString.toLowerCase()) || user.sn.toLowerCase().includes(searchString.toLowerCase()) || user.mail.toLowerCase().includes(searchString.toLowerCase()));
    });
  }
  }
  filterOrganizations(searchString) {
    if(this.originalOrganizations!=undefined)
      {
    this.availableOrganizations = this.originalOrganizations.filter(organization => {
      return (organization.name.toLowerCase().includes(searchString.toLowerCase()));
    });
  }
  }
  selectOrg(org) {
    this.selectedorg = org;
    this.roleAssignmentForm.get('organization').setValue(org.name + ' - ' + org.technicalId);
  }
  saveRoleAssignment() {
if(this.roleAssignmentForm.get('role').value==='ciam-admin' ||  this.roleAssignmentForm.get('role').value==='gsd-gas-admin')
{
      let params = 
        {
          "operation": "add",
          "field": "/authzRoles/-",
          "value": {"_ref" : ""}
        };
      const userID= this.selectedUser._id;
    if(this.roleAssignmentForm.get('role').value==='ciam-admin')
    {
      params.value._ref="internal/role/ciam-admin";
    }
    else if(this.roleAssignmentForm.get('role').value==='gsd-gas-admin')
      {
        params.value._ref="internal/role/gsd-gas-admin";
      }
      this.roleService.assigngnAdminRole(userID,params).subscribe((response: any) => {
            if(response.code==0)
            {
              this.router.navigate(['roleassignment/list/all']);
              
            }
            else
            {
              const msg = response.message;
                this.alerts.push({ type: 'danger', msg });
            }
          },
          err =>{
            if(err.status==0 || err.status==200)
              {
                this.router.navigate(['roleassignment/list/all']);

                
              }
              else 
              {
                const msg = err.error.message;
                this.alerts.push({ type: 'danger', msg });
              }
          }
        );
        
        }
        else if(this.roleAssignmentForm.get('role').value==='adminOfOrg')
        {
         const request= {
            "orgId" : this.selectedorg.id,
            "email" : this.selectedUser.mail
        }
        this.roleService.assignorgAdminRole('assign',request).subscribe((response: any) => {
          if(response.code!=0 && response.code != 201)
          {
            const msg = response.message
            this.alerts.push({ type: 'danger', msg });
          }
          else
          {
            this.router.navigate(['roleassignment/list/all']);
          }

        },err=>{
          if(err.status==0)
          {
            this.router.navigate(['roleassignment/list/all']);
 
          }
          else
          {
            const msg = err.error.message
            this.alerts.push({ type: 'danger', msg });
          }
          
        }
      );
        }  
    
  }
   //Authorization
   authorized() {
    return this.permission.isAdmin ;
};
authorizedBatch()
{
  return this.permission.isAdmin ;

}
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  selectService(service) {
    this.roleAssignmentForm.get('serviceId').setValue(service.name);
    this.selectedService = service;
  }
  dateChanges(event) {
    
  }
  selectfile(event) {
    const files = event.target.files;
    this.alerts = [];
    this.showprogress = false;
    
    if (files && files.length && files[0].type === 'text/csv') {
      if (files[0].size < 1048576) {
        this.selectedfile = files[0];
        this.selected = true;
      } else {
        this.selected = false;
        this.translateService.get('views.js.alerts.user.Selected file exceeds maximum file size of 1MB.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: res });
        });
      }
    } else {
      this.selected = false;
      this.translateService.get('views.js.alerts.organizations.Selecting file failed, make sure you choose a .csv file.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
    }
  }
  selectOrgnization(organization) {
    this.currentOrganization = organization;
    this.roleAssignmentForm.get('organization').setValue(organization.name + ' - ' + organization.technicalID);
  }
  download() {
    window.location.href = '/dis/v3/assets/resources/Batch_upload_template.csv';
  }
  upload() {
    this.alerts = [];
    this.progressmax = this.selectedfile[`size`];
    this.progressvalue = 0;
    this.progresswidth = '0';
    this.showprogress = true;
    this.roleService.batchAminUpload(this.selectedfile).subscribe(
      (event: HttpEvent<Blob>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            if (event.loaded > this.progressmax) {
              this.progressmax = event.loaded;
            }
            this.progressvalue = event.loaded;
            this.progresswidth = (this.progressvalue / this.progressmax) * 100 + '%';
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            break;
          case HttpEventType.Response:
            this.translateService.get('views.js.alerts.user.Uploaded file. An email will be sent once the changes have been processed.').subscribe(res => {
              this.alerts.push({ type: 'info', msg: res });
            });
            this.selectedfile = '';
            this.selected = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.translateService.get('views.js.alerts.user.An error occured while uploading the file.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: res });
        });
      }
    );
  }

}
