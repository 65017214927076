import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { PermissionService } from 'src/app/permission.service';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationListDialogComponentComponent } from 'src/app/organization-list-dialog-component/organization-list-dialog-component.component';
@Component({
  selector: 'app-pendingallusers',
  templateUrl: './pendingallusers.component.html',
  styleUrls: ['./pendingallusers.component.css']
})
export class PendingallusersComponent implements OnInit , AfterViewInit{
  @ViewChild('organizationListDialog') organizationListDialogTemplate: TemplateRef<any>;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userData = [];
  currentOrg: any;
  displayedColumns: string[] = ['status', 'lastname', 'firstname', 'email', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  displayStyle = 'none';
  filteredAndPagedIssues: Observable<[]>;
  filter = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  orginalData: any;
  organizationList: any=[];
  public constructor(public dialog: MatDialog,private commonService: CommonService, private router: Router,private permission: PermissionService) {
  }
  authorized() {
    return this.permission.isAdmin || this.permission.isHelpDeskAdmin;
  }
  ngAfterViewInit() {

    this.apiTrigger();
  }
  navigateUserDetail(row)
  {

    this.router.navigate(['/userrequest/' + row._id ])
    .then(nav => {
      this.commonService.previoususerlistlocation = '/userrequest/list/all';
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  apiTrigger()
  {
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        let request;
        if(Object.keys(this.filter).length>0)
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
         else
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};
                return this.commonService.getPendingAllUserData(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.attr.total;
        this.orginalData = data.users;
        return data.users;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  resetPaging(): void {
    this.apiTrigger();
  }
  applyFilterbyLastName(filterValue: string) {
   this.filter[`sn`] =  filterValue!=""?filterValue.toLowerCase():"";
   this.apiTrigger();
  }
  applyFilterbyFirstName(filterValue: string) {
    this.filter[`givenName`] = filterValue!=""?filterValue.toLowerCase():"";
    this.apiTrigger();
  }
  applyFilterbyEmail(filterValue: string) {
    this.filter[`mail`] = filterValue!=""?filterValue.toLowerCase():"";
    this.apiTrigger();
}
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false
    };
  }
  deletAPI(orgID,userID)
  {
    
  }
  openDialog(userOrganizations): void {
    const dialogRef = this.dialog.open(OrganizationListDialogComponentComponent, {
      width: '500px',
      data: { organizations: userOrganizations }
    });
  
    dialogRef.afterClosed().subscribe(result => {
    if (result?.deleted) {
      // Update the local list of organizations if necessary
      this.apiTrigger();
      this.filter ={};
      // Perform other actions based on the deletion
    }
    });
  }
 
deleteOrganizationUser(row)
{
  if(row['memberOfOrg']!=null && row['memberOfOrg'].length>1)
  {
    this.organizationList = row;
    this.openDialog(this.organizationList);
    
  }
  else
  {
    this.commonService.deletePendingUser(row['_id'] ).subscribe(data =>
      {
        this.filter = {};
        this.apiTrigger();
      },
      err =>
      {
      }
      );  }
}
gotoHomePage() {
  this.commonService.titleSub = 'a new';
  this.router.navigate([''])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
}
gotoUserRequestNewPage() {
  this.commonService.titleSub = 'a new';
  this.router.navigate(['userrequest/new'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
}

}
