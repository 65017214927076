import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { CommonService } from './common.service';
import { CONFIG } from './config';
import { PermissionService } from './permission.service';
import { IdleSessionTimeout } from 'idle-session-timeout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CurrentUser } from './entities/current-user';
declare var $: any;

// import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
// import {Keepalive} from '@ng-idle/keepalive';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  title = 'DISv3_FE';
  mainPage=true;
  privacy_touFlag=true;
  languages = CONFIG.LOCALES.locales;
  privacynoticeLanguages = ['Global-EN', 'China-EN', 'China-ZH', 'Japanese', 'Belgium-EN','Swiss-EN'];
  privacyLang:string;
  touLang:string
  mainLang: string;
  currentRoute: string;
  session = new IdleSessionTimeout(15*10);
  roles =[];
  @ViewChild('content') modalContent: TemplateRef<any>;
  @ViewChild('confirmModal') confirmModal: any;
  text: string;
  countdown =0;
  spinnerFlag=false;
  IsVisible =true;
  currentuser:CurrentUser
  organizations: any;
  // idleState = 'NOT_STARTED';
  // countdown?: number = null;
  // lastPing?: Date = null;
  constructor(@Inject(DOCUMENT) private document: Document, private permissionService: PermissionService, public translateService: TranslateService, private router: Router, private commonService: CommonService, private modalService: NgbModal,private idle: Idle, private keepalive: Keepalive) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('EN');
    
    if (window.location.href.indexOf("public/#/set") > -1) {
      const href = this.document.location.href;
      let newString = href.replace("public/#/set", "public/set");
      window.location.href= newString;
    }
   else  if (window.location.href.indexOf("public/#/otpset") > -1) {
      const href = this.document.location.href;
      let newString = href.replace("public/#/otpset", "public/otpset");
      window.location.href= newString;
    }
   else  if (window.location.href.indexOf("public/#/reset") > -1) {
      const href = this.document.location.href;
      let newString = href.replace("public/#/reset", "public/reset");
      window.location.href= newString;
    }
    else  if (window.location.href.indexOf("#/dis") > -1) {
      const href = this.document.location.href;
      let newString = href.replace("#/dis", "");
      window.location.href= newString;
    }
    else if (window.location.href.indexOf("#") > -1) {
      // this.router.navigate(['/public/404']);
    }

    this.commonService.baseHref = this.document.location.origin;
    // the lang to use, if the lang isn't available, it will use the current loader to get them
//    translateService.use('EN');
    idle.setIdle(900); // this will be 58*60

    idle.setTimeout(60); //counter time.

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      //alert("Idle End")
      this.IsVisible = false;
      
    });// this is the palce to do somthing if user comes back after being idle.

    idle.onTimeout.subscribe(() => {
      //alert("Idle timeout start")
      this.IsVisible = false;
      // this.router.navigate(['/oidc/logout']);
      window.location.href = "/dis/oidc/logout";
      document.getElementById('myModal').style.display = "none";
    });// this is place for redirect to login page.

    idle.onIdleStart.subscribe(() =>{
      //alert("Idle start");
    }); // this is place to do somthing when user goes Idle.

    idle.onTimeoutWarning.subscribe((countdown) => {
      //alert("Idle Timeout warning")
      this.countdown =   countdown ;
      this.IsVisible = true;
      document.getElementById('myModal').style.display = "block";
    }); // this is the place for alert to notify of the logout 

    keepalive.interval(15);
  }
  reset() {
    if( document.getElementById('myModal') !=null)
    document.getElementById('myModal').style.display = "none";
    const currentUrl = window.location.pathname;
   
    if ((currentUrl.indexOf('/dis/public') > -1 ) )
    {
      this.spinnerFlag=false;
    }
    if ((currentUrl.indexOf('/dis/public/privacynotice') > -1 ) )
    {
      this.mainPage=false;
    }
    else if(currentUrl.indexOf('/dis/public/tou')>-1)
    {
      this.mainPage=false;
    }
    else
    {
      this.mainPage=true;
    }
    if ((currentUrl.indexOf('/dis/public') > -1 )||(currentUrl.indexOf('/dis/saml')>-1)) {
      
      this.idle.stop();
    }   
    else{
      this.idle.watch();      
    }
  }
  ngOnInit(): void {
    this.reset(); 
    //this.commonService.setComponentLanguage('EN');
    this.commonService.getCompLanguage().subscribe(lang => {
      if (lang) {
        this.translateService.use(lang);
        this.mainLang = lang;
      }
      else
      {
        this.mainLang ='EN';
      }
    });
   
    if(localStorage.getItem("currentUserDetails"))
      {
    this.currentuser = JSON.parse(localStorage.getItem("currentUserDetails"));
        if(this.currentuser[`peferences`]!=null)
          {
            if(this.currentuser['preferences'].preferredLanguage =='' ||this.currentuser['preferences'].preferredLanguage === null )
              this.commonService.setComponentLanguage("EN");
            else
              this.commonService.setComponentLanguage(this.currentuser['preferences'].preferredLanguage);
          }
        if(this.currentuser['authzRoles']!=null &&this.currentuser['authzRoles'].length>0)
          {
            for (let person of this.currentuser['authzRoles']) {
              this.roles.push(person['_refResourceId']);
          }

         }
        if(this.currentuser['adminOfOrg'].length >0)
        {
          this.roles.push('adminOfOrg')
        }
       for(let roles of this.roles)
        {
        this.permissionService.isAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('ciam-admin') >= 0 : false;
      //  this.permissionService.isRoleAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisRoleAdmin') >= 0 : false;
     //   this.permissionService.isRoleAssignmentAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
       // this.permissionService.isContractAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisCctAdmin') >= 0 : false;
        //this.permissionService.isUserAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
        //this.permissionService.isBatchAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisBatchAdmin') >= 0 : false;
        this.permissionService.isOrganizationAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
        //this.permissionService.isGroupAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisGrpAdmin') >= 0 : false;
        //this.permissionService.isGroupAssignAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisGrpAssign') >= 0 : false;
        //this.permissionService.isServiceAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisSrvAdmin') >= 0 : false;
        this.permissionService.isHelpDeskAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('gsd-gas-admin') >= 0 : false;
       // this.permissionService.isStatsAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisStatsAdmin') >= 0 : false;
        //this.permissionService.isSPregistrarAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisSPregistrarAdmin') >= 0 : false;
    }

      this.currentuser = JSON.parse(localStorage.getItem("currentUserDetails"));

       this.availableOrganizations();



    }
  }
  availableOrganizations()
  {
    this.commonService.getAllAvailableOrgs().subscribe(
      data => {
        this.spinnerFlag =false;
        if(data.body != null)
          {
                this.organizations = data.body;
                if(this.organizations.length ===0)
                {
                    const authToken = localStorage.getItem('authToken');
                    if(authToken!=null)
                    {
                      this.commonService.getCurrentUser().subscribe(data =>
                        {
                          this.availableOrganizations();
                          localStorage.setItem('currentUserDetails', JSON.stringify(data.body));

                        },
                        err =>{
                          this.router.navigate(['/login'])

                        }
                      )
                    }
                    else
                    {
                      this.router.navigate(['/login'])
                    }

                }
                this.commonService.getCurrentOrg().subscribe(
                  data => {
                    if(data.body !=null)
                      {
                    this.commonService.selectedOrg = data.body;
                    }
                    else
                    {
                      if(this.organizations.length >0)
                      this.commonService.selectedOrg = this.organizations[0];
                    }
                  }, error => {}
                );
                  
                    }
      });
  }
  public changeLanguage(): void {
    this.translateService.use(this.mainLang);
    this.commonService.setComponentLanguage(this.mainLang);
  }
  openModal(modalContent) {
    // this.modalService.open(modalContent);
    document.getElementById('openModalButton').click();
    // $('#confirmModal').modal('show');
  }
  logout()
  {
    document.getElementById('myModal').style.display = "none";
    this.idle.stop();
    window.location.href = "/dis/oidc/logout";
    // this.router.navigate(['/oidc/logout']);

  }
 

}

