import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RolesService } from '../../roles-service.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../common.service';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { PermissionService } from '../../permission.service';
import { ServiceManagementService } from 'src/app/Service_Management/service-management.service';

@Component({
  selector: 'app-role-assignment-new',
  templateUrl: './role-assignment-new.component.html',
  styleUrls: ['./role-assignment-new.component.css']
})
export class RoleAssignmentNewComponent implements OnInit {

  roleAssignmentForm: FormGroup;
  currentUsers: any;
  originalUsers: any;
  selectedUser: any;
  currentRoles: any;
  originalRoles: any;
  selectedRole: any;
  availableServices: any;
  selectedService: any;
  currentOrganization: any;
  date = new Date();
  showhelp = false;
  selectedfile = {};
  selectedOrgName: string;
  selected = false;
  showprogress = false;
  alerts = [];
  progresswidth;
  progressmax = 0;
  progressvalue = 0;
  constructor(private servicemanagementservice:ServiceManagementService,private roleService: RolesService, private permission: PermissionService , private router: Router, private translateService: TranslateService, private commonService: CommonService) {
    this.roleAssignmentForm = new FormGroup({
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null, Validators.required),
      'user': new FormControl(null, [Validators.minLength(3),Validators.required]),
      'role': new FormControl(null,[Validators.required]),
      'serviceId': new FormControl(null)
    });
  }
  disableDate()
  {
    return false;
  }private formatDate(dateString)
  {
        const date = new Date(dateString);

        // Extracting year, month, and day as numbers
        const year = date.getFullYear(); // 2024
        const month = date.getMonth() + 1; // 12
        const day = date.getDate(); // 12

        // Formatting month and day with leading zeros for string representation
        const monthString = month < 10 ? '0' + month : month.toString();
        const dayString = day < 10 ? '0' + day : day.toString();

        // Constructing the formatted date string
        const formattedDate = `${year}-${monthString}-${dayString}`;
        return formattedDate
  }
  fetchUsers() {
    if (this.roleAssignmentForm.get('user').status=="VALID") {
      this.roleService.getCurrentOrganizationUsers(this.roleAssignmentForm.get('user').value).subscribe(
        data => {
          this.currentUsers = data.body;  
          this.originalUsers = data.body;
             },
        error => {
        }
      );
    }
  }
  // updateRoleDetailsWithServiceName(roledetails, servicedetails) {
  //   let roleDetails =[];
  //   for (const roleDetail of roledetails) {
  //     let service = servicedetails.find(sd => sd._id === roleDetail.serviceId);
  //     if (!service) {
  //       // Fetch from API if not found in the existing array
  //       //service = await fetchServiceDetailsFromAPI(roleDetail.serviceId);
  //       this.servicemanagementservice.getServiceDetailswithId(roleDetail.serviceId).subscribe(data =>
  //         {
  //           //servicedetails.push(service);
  //         // Optionally update the local cache
  //         roleDetail.serviceName = data.body['name'];
  //         roleDetail.technicalId=data.body['technicalId'];
  //           // Update the role detail
  //         roleDetails.push(roleDetail);
  //         });
  //     }
  //     else
  //     {
  //     roleDetail.serviceName = service.name;  
  //     roleDetails.push(roleDetail);
  //     }// Update the role detail
  //   }
  //   return roleDetails;
  // }
  ngOnInit(): void {
    let tomorrow = new Date(); // Create a new Date object representing today.
    tomorrow.setDate(tomorrow.getDate()); // Increment the day by 1.
    this.roleAssignmentForm.get('startDate').patchValue(this.formatDate(tomorrow));
    this.selectedOrgName = this.commonService.selectedOrg[`name`];
    this.currentOrganization=this.commonService.selectedOrg;
    this.roleService.getServices().subscribe((response: any) =>
      {
        this.availableServices = response.body;
      });
      this.commonService.getOrgDetails().subscribe(
        data => {
          const roledetails=data[`roleDetails`].filter(roledetail => roledetail.status === "active");
          this.currentRoles=roledetails;
          this.originalRoles = this.currentRoles;
}
);
    // this.roleService.getCurrentOrganizationRoles().subscribe(
    //   data => {
    //     if(data[0][`code`]==403)
    //     {
    //         this.currentRoles=[];
    //         this.originalRoles =[];
    //     }
    //     else
    //     {
    //       this.currentRoles = data.body;
    //       this.originalRoles = data.body;
    //     }
       
    //        },
    //   error => {
    //   }
    // );
   
  }
  authorized() {

    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin ;
  }
  selectUser(user) {
    this.selectedUser = user;
    this.roleAssignmentForm.get('user').setValue((user.sn + ' ' + user.givenName + ' (' + user.mail + ')'));
  }
  filterUsers(searchString) {
    if(this.originalUsers!=undefined)
    {
    this.currentUsers = this.originalUsers.filter(user => {
      return (user.sn.toLowerCase().includes(searchString.toLowerCase()) || user.givenName.toLowerCase().includes(searchString.toLowerCase()) || user.mail.toLowerCase().includes(searchString.toLowerCase()));
    });
  }
  }
  filterRoles(searchString) {
    if(this.originalRoles!=undefined)
    {
    this.currentRoles = this.originalRoles.filter(role => {
      return (role.name.toLowerCase().includes(searchString.toLowerCase()) || role.description.toLowerCase().includes(searchString.toLowerCase()) || role.services[0].technicalId.toLowerCase().includes(searchString.toLowerCase()));
    });
  }
  }
  selectRole(role) {
    this.selectedRole = role;
    this.roleAssignmentForm.get('role').setValue((role.name + ' - ' + role.description + ' - ' + '(' + role?.technicalId + ')'));
  }
  
  saveRoleAssignment() {
    const startDate = this.formatDate(this.roleAssignmentForm.get('startDate').value);
    const endDate=this.formatDate(this.roleAssignmentForm.get('endDate').value)
    if (startDate <= endDate) {
      const params = {
        userId: this.selectedUser._refResourceId,
        roleId: this.selectedRole._id,
        orgId: this.currentOrganization.id,
        status: "active",
        endDate: endDate,
        // startDate: new Date(this.roleAssignmentForm.value.startDate).getTime()
        startDate: startDate
      };
     
        
          this.roleService.saveRoleAssignment(params).subscribe((response: any) => {
           if(response.code==201)
              this.router.navigate(['roleassignment/list']);
            else
            {
              const msg = response.message;
              this.alerts.push({ type: 'danger', msg });
            }

          });
        
      
    } else {
      const msg = 'Start date cannot be later then end date.';
      this.alerts.push({ type: 'danger', msg });
      // this.deleteAlert(msg);
    }
  }
  // deleteAlert(msg) {
  //   setTimeout(() => {
  //     this.alerts.splice(this.alerts.findIndex(alert => alert.msg === msg), 1);
  //   }, 5000);
  // }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  selectService(service) {
    this.roleAssignmentForm.get('serviceId').setValue(service.name);
    this.selectedService = service;
  }
  dateChanges(event) {
    
  }
  selectfile(event) {
    const files = event.target.files;
    this.alerts = [];
    this.showprogress = false;
    
    if (files && files.length && files[0].type === 'text/csv') {
      if (files[0].size < 1048576) {
        this.selectedfile = files[0];
        this.selected = true;
      } else {
        this.selected = false;
        this.translateService.get('views.js.alerts.user.Selected file exceeds maximum file size of 1MB.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: res });
        });
      }
    } else {
      this.selected = false;
      this.translateService.get('views.js.alerts.organizations.Selecting file failed, make sure you choose a .csv file.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
    }
  }
  download() {
    window.location.href = '/dis/v3/assets/resources/Batch_upload_template.csv';
  }
  upload() {
    this.alerts = [];
    this.progressmax = this.selectedfile[`size`];
    this.progressvalue = 0;
    this.progresswidth = '0';
    this.showprogress = true;
    this.roleService.batchuserUpload(this.selectedfile).subscribe(
      (event: HttpEvent<Blob>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            if (event.loaded > this.progressmax) {
              this.progressmax = event.loaded;
            }
            this.progressvalue = event.loaded;
            this.progresswidth = (this.progressvalue / this.progressmax) * 100 + '%';
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            break;
          case HttpEventType.Response:
            this.translateService.get('views.js.alerts.user.Uploaded file. An email will be sent once the changes have been processed.').subscribe(res => {
              this.alerts.push({ type: 'info', msg: res });
            });
            this.selectedfile = '';
            this.selected = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.translateService.get('views.js.alerts.user.An error occured while uploading the file.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: err.error });
        });
      }
    );
  }
}
