<div id="batch" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.user.Home' | translate}}</a></li>
        <li class="active">{{'views.user.Batch upload' | translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.user.Log out' | translate}}</a></li>
    </ol>


    <div class="titleicon">
      <img src="assets/images/homepage/batch.png"
            alt="{{'views.user.New user' | translate}}" /></div>
            <h1>{{'views.user.Batch upload for organization' | translate}} {{selectedOrgName}}</h1>
            <div *ngFor = "let alert of alerts">
              <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                  {{alert.msg}}
                  <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
          font-size: 22px;
         float:right;
              "></i>
              </p>
          </div>
    <div class="panel panel-default" >
        <div class="panel-heading"></div>
        <div class="panel-body">
            <div style="float:right">
                <button type="button" class="btn btn-default btn-xs" (click)="showhelp = !showhelp" title="{{'views.user.Show help' | translate}}">
                  <span class="glyphicon glyphicon-question-sign" style="margin-right: 10px"></span>
                </button>
              </div>
              <div *ngIf="showhelp" style="overflow: auto;">
                <p><span class="">
                  <img src="assets/img/batch_upload_highres.png" width="90%" height="90%" style="display:block; margin: 0 auto"></span></p>
                <p>
                  <span class="">
                    {{'views.user.The batch upload module lets you upload a CSV file containing instructions for operations on users for the current organization' | translate}}
                    ({{selectedOrgName}})
                    {{'views.user.or for a specified organization.' | translate}}
                    {{'views.user.The operations will result in flows that are exactly the same as using the user interface.' | translate}}
                    {{'views.user.People will get emails etc when you perform actions on their accounts.' | translate}}
                    {{'views.user.The supported operations are: registering a user (with initial roles if needed), suspending a user, activating a user and deleting a user.' | translate}}
                  </span>
                </p>
  
                <p><span class="">{{'views.user.The CSV file should contain lines of the following format:' | translate}}</span></p>
                
                <p>
                  <span class="batchcmd">OPERATION;USERTYPE;EMAIL;FIRSTNAME;LASTNAME;MOBILE;MAINLANGUAGE;ORGTECHID;SERVICETECHID;ROLES;USERTYPE;ROLEENDDATE(dd-mm-yyyy)</span>
                </p>
                
                <p>
                  <span class="batchcmd">OPERATION</span>
                  <span class=""> 
                    {{'views.user.can be' | translate}}
                    <span class="batchcmd">REGISTER, SUSPEND, ACTIVATE</span>
                    {{'views.user.or' | translate}}
                    <span class="batchcmd">DELETE</span>. 
                    {{'views.user.For' | translate}}
                    <span class="batchcmd">REGISTER</span>,
                    {{'views.user.the required fields are' | translate}}
                    <span class="batchcmd">EMAIL, FIRSTNAME</span> and <span class="batchcmd">LASTNAME</span>. 
                    {{'views.user.For the other operations the field' | translate}}
                    <span class="batchcmd">EMAIL</span>
                    {{'views.user.is sufficient' | translate}}
                  </span>
                </p>
  
                <p>
                  <span class="batchcmd">ROLES</span>
                  <span class=""> 
                        {{"views.user.are one or more technical ID's of roles in the form of" | translate}}
                        <span class="batchcmd">ROLE1|ROLE2|ROLE3</span>.
                        {{'views.user.This will only work if you are role assignment admin (DisRoleAdmin) of the specified organization and for the service to which the role is linked to.' | translate}}
                  </span>
                </p>
                 
                <p><span class="">{{'views.user.The CSV file should not contain headers. The first line is data.' | translate}}</span></p> 
                    
                <p><span class="">{{'views.user.Examples of correct lines are:' | translate}}</span></p> 
                 
                <p>
                  <span class="batchcmd">
                    REGISTER;user1@test.com;user1firstname;testlastname;+11234567890;MAINLANGUAGE;ORGTECHID;SERVICETECHID;ROLE1|ROLE2;azureb2b;ROLEENDDATE<br />
                    REGISTER;user1@test.com;user1firstname;testlastname;+11234567890;MAINLANGUAGE;ORGTECHID;SERVICETECHID;ROLE1;azureb2b<br />
                    REGISTER;user1@test.com;user1firstname;testlastname;+11234567890;MAINLANGUAGE;ORGTECHID;;ROLE1;<br />
                    REGISTER;user1@test.com;user1firstname;testlastname;;;;;ROLE1<br />
                    REGISTER;azureb2b;user1@test.com;user1firstname;testlastname;;;;;<br />
                    SUSPEND;user1@test.com;;;;;;;<br />
                    ACTIVATE;user1@test.com;;;;;;;<br />
                    DELETE;user1@test.com;;;;;;;<br />
                    UPDATE;user1@test.com;;;+11234567890;;;;
                    UPDATE;user1@test.com;;;;MAINLANGUAGE;;;<br/>
                    UPDATE;user1@test.com;user1firstname;testlastname;;;;;clientidp
                  </span>
                </p>
  
                <p><span class="">{{'views.user.You will be notified by mail with the results of the batch changes.' | translate}}</span></p> 
              
                <br><br>
              </div>
              <div *ngIf="selected"><p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size / 1000 | number:0}} kb)</span></p>
              </div>
              <div *ngIf="showprogress" class="progress" style="height: 20px">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{'width': progresswidth}"
                  
                  aria-valuenow="(progressvalue / progressmax)*100 | number:0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                
                {{(progressvalue / progressmax)*100 | number:0 }}%
                </div>
              </div>  
              <div class="btn-group">
                <button type="button" class="btn btn-default" (click)="download()" title="Download file">
                        <span class="glyphicon glyphicon-save" style="margin-right: 10px"></span>
                        {{"views.user.Download batch upload template"| translate}}
                </button>
        </div>
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <div class="choose_file" >
              <button type="button"   class="btn btn-default btn-group" title="Select file">
                
                <span class="glyphicon glyphicon-paperclip" style="margin-right: 10px"></span>
                  {{'views.user.Select your CSV file' | translate}}
              </button>
              <input type="file" (change)="selectfile($event)"  accept=".csv,.CSV">  
            </div>
          </div>
          <div class="btn-group">
              <button [attr.disabled]="selected==false ? '':null" type="button" class="btn btn-default" (click)="upload()" title="Upload file">
                <span class="glyphicon glyphicon-open" style="margin-right: 10px"></span>
                {{'views.user.Upload file' | translate}}
              </button>
          </div>
      </div>
    
            </div>
    </div>
    </div>
   
   <div style="display: flex;margin-left: 7%;
    margin-top: 19%;">
      <app-terms-conditions></app-terms-conditions>
  </div>