import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private _baseHref: string;
  private _token;
  public get baseHref(): string {
    return this._baseHref;
  }
  public set baseHref(value: string) {
    this._baseHref = value;
  }
  public get token() {
    return this._token;
  }
  public set token(value) {
    this._token = value;
  }
  constructor(private commonService: CommonService , private http: HttpClient)
  {
    this.baseHref = commonService.baseHref;
    this.token = commonService.token;
  }
  getOrganizations(params) {
    // /dis/rest/organization/fetch
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/organization/', params, requestOptions);
  }
  getOrganizationsList() {
    return this.http.get<any>(this._baseHref + '/dis/rest/organization');
  }
  getAllOrganizations(request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
        });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/organization', request, requestOptions);
  }
  saveOrganization(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/organization', params, requestOptions);
  }
  updateOrganization(id, request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.patch(this._baseHref + '/dis/rest/organization/' + id, request, requestOptions);
  }
  deleteOrganization(id, request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.delete(this._baseHref + '/dis/rest/organization/' + id, requestOptions);
  }
  deleteServiceFromOrg(serviceid,orgid)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.delete(this._baseHref + '/dis/rest/service/orgService?orgId=' + orgid+"&serviceId="+serviceid, requestOptions);
  }
  /**Old Code */
  deleteorsuspenduserRoles(id,request)
  {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrole/' + id, request, requestOptions);
  }

  updatestatusUserbyOrg(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/organizationuser/updateOrgUserOrgStatus', request, requestOptions);
  }
  deletestatusUserbyOrg(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/organizationuser/deleteUserFromOrg', request, requestOptions);
  }
  getOrganizationDetails(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/organization/' + id);
  }
  getserviceDetails(id)
  {
    const arrayIds = Array.isArray(id) ? id : [];

    let requests = arrayIds.map(id => this.http.get(this._baseHref + '/dis/rest/service/' + id));
    
    // forkJoin will execute all HTTP requests and emit the last emitted value from each as an array
    return forkJoin(requests);
    //return this.http.get<any>(this._baseHref + '/dis/rest/service/' + id);

  }
  getorganizationcontract(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/organizationcontract/org/' + id);
  }
  getorganizationuserroles(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/userrole/org/' + id);
  }
  getorganizationusers(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/organizationuser/org/' + id);
  }
  getavailableServices()
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/service' );
  }
  orgService(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/service/orgService', request, requestOptions);
  }
  saveorguserrequest(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/organizationuser/saveorgusersrequest', request, requestOptions);
  }
}
